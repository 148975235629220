import React from 'react';

import RocketLoading from '../components/atoms/loading';

const Index = () => {
  React.useEffect(() => {
    // asd
    window.location.href = '/login';
  });
  return <RocketLoading />;
};

export default Index;
